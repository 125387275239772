import './CompanyAddress.scss';

import React from 'react';

import { classNames } from '../../utils';

function CompanyAddress({includeName, className}) {
  return (
    <address className={classNames('company-address', className)}>
      {includeName ? <strong>Tri-Star Systems<br /></strong> : null}
      16401 Swingley Ridge Road Suite 250<br />
    Chesterfield, MO 63017<br /><br />
  <strong>Office Hours:</strong><br />
  Monday - Friday<br />
  7:00 a.m. - 5:00 p.m. Central Time<br /><br />

<strong>Holiday Schedule 2023:</strong>
  <table border="1" cellPadding="3" cellSpacing="0" className="contactTable">
  <tbody>
  <tr>
  <td>January 2</td>
  <td>New Year&#39;s Day, observance</td>
  </tr>
  <tr>
  <td>May 29</td>
  <td>Memorial Day</td>
  </tr>
  <tr>
  <td>July 3</td>
  <td>Independence Day, extended day</td>
  </tr>
  <tr>
  <td>July 4</td>
  <td>Independence Day</td>
  </tr>
  <tr>
  <td>September 4</td>
  <td>Labor Day</td>
  </tr>
  <tr>
  <td>November 23</td>
  <td>Thanksgiving Day</td>
  </tr>
  <tr>
  <td>November 24</td>
  <td>Thanksgiving, extended day</td>
  </tr>
  <tr>
  <td>December 25</td>
  <td>Christmas</td>
  </tr>
  <tr>
  <td>December 26</td>
  <td>Christmas, observance</td>
  </tr>
  <tr>
  <td>January 1</td>
  <td>New Year&#39;s Day, observance</td>
  </tr>
  </tbody>
  </table>
    </address>
  );
}

export default CompanyAddress;
